  :host {
    --track-width: 4px;
  }

  @media only screen and (min-width: POSTCSS_LIT_0) {
    .navWrapper {
      overflow: initial;
    }
  }

  .header {
    grid-area: header;
    font-size: var(--sl-font-size-large);
    font-weight: 500;
    line-height: 1;
  }

  .content {
    grid-area: main;
  }

  .nav {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: var(--sl-spacing-medium);
  }

  .tablist {
    display: flex;
    margin: 0;
    list-style: none;
    padding: 0;
    gap: 0.5rem;
  }

  .show-indicator .tablist {
    margin-left: var(--track-width);
  }

  @media only screen and (min-width: POSTCSS_LIT_1) {
    .tablist {
      flex-direction: column;
    }
  }

  .track {
    display: none;
    position: absolute;
    top: 0;
    height: 100%;
    width: var(--track-width);
    border-radius: var(--track-width);
    background-color: var(--sl-color-neutral-100);
    box-shadow: inset 0 0 2px var(--sl-color-neutral-300);
  }

  .indicator {
    display: none;
    position: absolute;
    width: var(--track-width);
    border-radius: var(--track-width);
    background-color: var(--sl-color-primary-500);
  }

  @media only screen and (min-width: POSTCSS_LIT_2) {
    .show-indicator .track,
    .show-indicator .indicator {
      display: block;
    }
  }
